<template>
  <div class="container-fluid banner">
    <div class="row text-center">
      <div class="banner-text">
        <h2 classs="mb-4">{{ $t('about.title') }}</h2>
        <p class="mt-4 mb-0">
          {{ $t('about.description') }}
        </p>
      </div>
    </div>
  </div>
  <div class="container">
    <description-row />
    <sections-row />
  </div>
</template>

<script>
import SectionsRow from '@/components/about/SectionsRow';
import DescriptionRow from '@/components/about/DescriptionRow';

export default {
  name: 'About',
  components: {
    SectionsRow,
    DescriptionRow,
  },
};
</script>

<style lang="scss">
.sections-row {
  .col-12 {
    margin-bottom: 3rem;
  }
}
</style>
