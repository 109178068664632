<template>
  <div class="row mt-4 mb-4 text-center">
    <div class="col-12">
      <p>
        {{ $t('about.description2') }}
      </p>
    </div>
    <div class="col-12">
      <p>{{ $t('about.description3') }}</p>
    </div>
  </div>
</template>

<script>
export default {
    name: 'DescriptionRow'
};
</script>

<style></style>
