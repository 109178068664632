<template>
  <div class="row sections-row">
    <div class="col-12 col-lg-6 about-card-col">
      <div class="about-card card shadow">
        <localized-link :to="{ name: 'Explore' }"
          ><h3>{{ $t('about.item_title1') }}</h3></localized-link
        >
        <p>{{ $t('about.description_item1') }}</p>
        <app-see-link link="Explore" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <img
        src="@/assets/img/explore.jpg"
        class="img-fluid shadow"
        alt="Fuel compass - Explore"
      />
    </div>
    <div class="col-12 col-lg-6 about-card-col">
      <div class="about-card card shadow">
        <localized-link :to="{ name: 'Evaluate' }"
          ><h3>{{ $t('about.item_title2') }}</h3></localized-link
        >
        <p>{{ $t('about.description_item2') }}</p>
        <app-see-link link="Evaluate" />
      </div>
    </div>
    <div class="col-12 col-lg-6 about-card-col">
      <img
        src="@/assets/img/evaluate.jpg"
        class="img-fluid shadow"
        alt="Fuel compass - Evaluate"
      />
    </div>
    <div class="col-12 col-lg-6 about-card-col">
      <div class="about-card card shadow">
        <localized-link :to="{ name: 'Compare' }"
          ><h3>{{ $t('about.item_title3') }}</h3></localized-link
        >
        <p>{{ $t('about.description_item3') }}</p>
        <app-see-link link="Compare" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <img
        src="@/assets/img/compare.jpg"
        class="img-fluid shadow"
        alt="Fuel compass - Compare"
      />
    </div>
  </div>
</template>

<script>
export default {
    name: 'SectionsRow'
};
</script>

<style></style>
